body {
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
  width: 100%;
}

.appHeader {
  height: 5%;
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.headerTab {
  width: 15%;
  background-color: #F48120;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 2px 5px 5px #FAAD3F;
}

.headerTab:nth-child(3) {
  border-right: none;
}

.headerTab:hover {
  cursor: pointer;
  background-color: #282c34;
  color: white;
  transition: all 0.2s;
  box-shadow: none;
}

.appBody {
  position: fixed;
  top: 7%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}