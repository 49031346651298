.chartContainer {
    margin: 40px;
    align-self: center;
    width: 70%;
    display: flex;
}

.domainTable {
    width: 100%;
    border-collapse: collapse;
}

.domainTable td, .domainTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    width: 30%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #F48120;
    color: white;
}

tr{
    text-align: left;
}

.rankChangeContainer {
}

.rankChangeContainer > div {
    align-self: center;
    display: inline-block;
    width: 25px;
    position: relative;
    top: -4px;
}

.rankChangeContainer > img {
    display: inline-block;
    width: 20px;
}